import { initPulse } from '../../core/js/pulse/pulse-init.js';

const useImpressions = true;

const extendedConfig = {
  enableImpressions: useImpressions,
  experiments: [],
};

export const initBtPulse = () => {
  initPulse(extendedConfig);
};
