import { getUserStatus } from '../../core/js/schibsted-account/index.client.js';

export const initCCHeaderIcon = async () => {
  const ccHeaderDiv = document.querySelector('#ch-cc-notifications');
  const userStatus = await getUserStatus();

  if (ccHeaderDiv && userStatus.isLoggedIn) {
    const ccAnchor = document.querySelector('#ch-cc-notifications-link');

    const [{ createElement, createRoot }, { HeaderIcon }] = await Promise.all([
      import('../../core/js/react-library.js'),
      import('../../../src/regional/notifications/HeaderIcon.js'),
    ]);

    const root = createRoot(ccAnchor);
    root.render(createElement(HeaderIcon));
  }
};
