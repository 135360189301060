export const initNotifications = async () => {
  const container = document.querySelector('#notifications-center');

  if (container) {
    const [{ createElement, createRoot }, { Notifications }] =
      await Promise.all([
        import('../../core/js/react-library.js'),
        import('../../../src/bt/views-react/notifications/index.js'),
      ]);

    container.classList.add('notification-wrapper');

    const root = createRoot(container);
    root.render(createElement(Notifications));
  }
};
